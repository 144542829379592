
.dot {
  @apply w-[8px] h-[8px] bg-[#A15A1C] opacity-0 rounded-[4px];

  animation: fade 1.4s infinite ease-in-out both;
}

.dotLoadingMobile {
  .dot {
    @apply w-[6px] h-[6px] rounded-[3px];
  }
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes fade {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
